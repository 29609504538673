// import React from "react"
// import Paypal from "gatsby-plugin-paypal"

// const PaylpalButton = () => (
//     <Paypal
//         style={{
//             shape: 'rect',
//             color: 'blue',
//             layout: 'horizontal',
//             label: 'paypal',
//         }}
//         amount={1}
//         currency="USD"
//     />
// )
// export default PaylpalButton

import React from 'react';
// import { PayPalButton } from "react-paypal-button-v2";
class PayPalBtn extends React.Component {
    // render() {
    //     const { amount, onSuccess, currency } = this.props;
    //     return (
    //         <PayPalButton
    //             amount={amount}
    //             currency={currency}
    //             onSuccess={(details, data) => onSuccess(details, data)}
    //             options={{
    //                 clientId: "sb"
    //             }}
    //         />
    //     );
    // }
}
export default PayPalBtn;