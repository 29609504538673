import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PastCommitteeMembers from "../components/about/past-committee-members";


const pastcommittee = () => {
    return (
        <Layout>
            <SEO
                title="Past Committee Members | Kentucky Tamil Sangam"
                desc="Past Committee Members | Kentucky Tamil Sangam"
                pathname="/kyts-members"
                keywords="kyts members"
            />
            <PastCommitteeMembers />
        </Layout>
    )
}

export default pastcommittee